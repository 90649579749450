import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { BaseService } from './base.service';
import { GlobalConstants } from './globalconstants.service';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService,
    private globalConstant: GlobalConstants
  ) { }

  getMasterData(types: string[]) {
    return this.httpClient.post<any>(this.baseService.GET_URL(this.globalConstant.MASTERDATALIST_URL),
    types,
      this.baseService.getHeaders());
  }

  getCreateProductModalMasterListResponse() {
    return forkJoin([
      this.getCompanyGroupMasterList(),
      this.getLanguageMasterList()
    ]);
  }

  getProductTitleMasterListResponse() {
    return forkJoin([
      this.getCompanyGroupMasterList(),
      this.getLanguageMasterList(),
      this.getVersionTypeMasterList(),
      this.getMediumMasterList(),
      this.getBindingMasterList(),
      this.getTrimSizeMasterList(),
      this.getSectionColorMasterList(),
      this.getAuthorTypeMasterList(),
      this.getPublisherMasterList()
    ]);
  }

  geProductEditionTabtMasterListResponse() {
    return forkJoin([
      this.getResponsibilityTypeMasterList(),
      this.getPublishStatusMasterList()
    ]);
  }

  getAuthorModuleMasterListResponse() {
    return forkJoin([
      this.getAuthorTitleMasterList(),
      this.getAuthorTypeMasterList(),
      this.getAddressTypeMasterList(),
      this.getContactTypeMasterList(),
      this.getCountryMasterList(),
      //this.getAffiliationMasterList()
    ]);
  }

  getAuthorAddressModalMasterListResponse() {
    return forkJoin([
      this.getAddressTypeMasterList(),
      this.getCountryMasterList(),
      this.getContactTypeMasterList() ,
      //this.getAffiliationMasterList()
    ]);
  }

  getProductPriceModalMasterListResponse() {
    return forkJoin([
      this.getPriceTypeMasterList(),
      this.getCurrencyMasterList()
    ]);
  }

  getProductDistribTabMasterListResponse() {
    return forkJoin([
      this.getSeasonMasterList(),
      this.getDiscGroupMasterList()
    ]);
  }

  getProductionSpecTabMasterListResponse() {
    return forkJoin([
      this.getTrimSizeMasterList(),
      this.getSectionColorMasterList(),
      this.getProductionClassificationMasterList(),
      this.getProductionFormatMasterList(),
      this.getProductionMethodMasterList(),
      this.getBindingMasterList(),
      this.getProductionSpineStyleMasterList(),
      this.getProductionBindingMethodMasterList(),
      this.GetSupplierMasterList()
    ]);
  }

  getProductionCoversTabMasterListResponse() {
    return forkJoin([
      this.getProductionBindingMaterialList(),
      this.getProductionSurfaceList(),
      this.getProductionColorationList(),
      this.getProductionSpecificationList(),
      this.getProductionFinishingList(),
      this.getProductionBindingPaperQualityList(),
      this.getProductionPerSheetList(),      
      this.getProductionBindingGrammageList(),
      this.getProductionBoardThicknessList(),
      this.getProductionFrontPaperColorlist(),
      this.getProductionBackPaperColorlist(),
      this.getProductionEndPaperGrammagelist(),
      this.getProductionExteriorColorList(),
      this.getProductionInteriorColorList(),
      this.getProductionEndpaperMaterialList()
    ]);
  }

  getProductionExtrasTabMasterListResponse() {
    return forkJoin([
      this.getProductionIllustrationTypeMasterList(),
      this.getProductionIllustrationCategoryMasterList()      
    ]);
  }

  getProductionPartsTabMasterListResponse() {
    return forkJoin([
      this.getProductionPartsNameList(),
      this.getProductionPartsMaterialList(),
      this.getProductionPartsPaperQualityList(),
      this.getProductionPartsGrammageList(),
      this.getProductionPartsPagesInchList(),
      this.getProductionPartsColorList(),
      this.getProductionPaperSizeList(),
      this.getProductionBleedList(),
      this.getProductionColorationList(),
      this.getProductionInsertMaterialList(),
      this.getProductionInsertColorationList(),
      this.getProductionInsertSpecList(),
      this.getProductionInsertRectoColorList(),
      this.getProductionInsertFinishingList(),
      this.getProductionInsertPagesPerSheetList(),
      this.getProductionInsertVersoColorList(),
      this.GetSupplierMasterList()
    ]);
  }

  getProductionScheduleTabMasterListResponse() {
    return forkJoin([
      this.getProductionScheduleTemplateList()
    ]);
  }

  getProductionNotesTabMasterListResponse() {
    return forkJoin([
      this.getProductionNotesTypeList()
    ]);
  }

  getProductionPurchaseTabMasterListResponse() {
    return forkJoin([
      this.getProductionPOTemplateList(),
      this.getProductionPODeliveryTypeList()
    ]);
  }

  geAdvSearchMasterListResponse() {
    return forkJoin([
      this.getVersionTypeMasterList(),
      this.getPublishStatusMasterList()
    ]);
  }

  getCompanyGroupMasterList() {
    return this.baseService.GET(this.globalConstant.COMPANYGROUPLIST_URL).pipe(map((response) => response));
  }

  getLanguageMasterList() {
    return this.baseService.GET(this.globalConstant.LANGUAGELIST_URL).pipe(map((response) => response));
  }

  getVersionTypeMasterList() {
    return this.baseService.GET(this.globalConstant.VERSIONTYPELIST_URL).pipe(map((response) => response));
  }

  getMediumMasterList() {
    return this.baseService.GET(this.globalConstant.MEDIUMLIST_URL).pipe(map((response) => response));
  }

  getBindingMasterList() {
    return this.baseService.GET(this.globalConstant.BINDINGLIST_URL).pipe(map((response) => response));
  }

  // getDisciplineMasterList() {
  //   return this.baseService.GET(this.globalConstant.DISCIPLINELIST_URL).pipe(map((response) => response));
  // }

  getResponsibilityTypeMasterList() {
    return this.baseService.GET(this.globalConstant.RESPONSIBILITYTYPELIST_URL).pipe(map((response) => response));
  }

  getPublishStatusMasterList() {
    return this.baseService.GET(this.globalConstant.PUBLISHSTATUSLIST_URL).pipe(map((response) => response));
  }

  getTrimSizeMasterList() {
    return this.baseService.GET(this.globalConstant.TRIMSIZELIST_URL).pipe(map((response) => response));
  }

  getSectionColorMasterList() {
    return this.baseService.GET(this.globalConstant.SECTIONCOLORLIST_URL).pipe(map((response) => response));
  }

  getAuthorTypeMasterList() {
    return this.baseService.GET(this.globalConstant.AUTHORTYPELIST_URL).pipe(map((response) => response));
  }
  getBookAuthorTypeMasterList() {
    return this.baseService.GET(this.globalConstant.BOOKAUTHORTYPELIST_URL).pipe(map((response) => response));
  }
  getAuthorTitleMasterList() {
    return this.baseService.GET(this.globalConstant.AUTHORTITLELIST_URL).pipe(map((response) => response));
  }

  getAddressTypeMasterList() {
    return this.baseService.GET(this.globalConstant.ADDRESSTYPELIST_URL).pipe(map((response) => response));
  }

  getContactTypeMasterList() {
    return this.baseService.GET(this.globalConstant.CONTACTTYPELIST_URL).pipe(map((response) => response));
  }

  getCountryMasterList() {
    return this.baseService.GET(this.globalConstant.COUNTRYLIST_URL).pipe(map((response) => response));
  }
  getAffiliationMasterList() {
    return this.baseService.GET(this.globalConstant.AFFILIATIONLIST_URL).pipe(map((response) => response));
  }
  getClassificationCategoryMasterList() {
    return this.baseService.GET(this.globalConstant.CLASSIFICATIONCATEGORYLIST_URL).pipe(map((response) => response));
  }

  getPaymentMethodMasterList() {
    return this.baseService.GET(this.globalConstant.PAYMENTMETHODLIST_URL).pipe(map((response) => response));
  }

  getPriceTypeMasterList() {
    return this.baseService.GET(this.globalConstant.PRICETYPELIST_URL).pipe(map((response) => response));
  }

  getCurrencyMasterList() {
    return this.baseService.GET(this.globalConstant.CURRENCYLIST_URL).pipe(map((response) => response));
  }

  getRelationTypeMasterList() {
    return this.baseService.GET(this.globalConstant.RELATIONTYPELIST_URL).pipe(map((response) => response));
  }

  getSeasonMasterList() {
    return this.baseService.GET(this.globalConstant.SEASONLIST_URL).pipe(map((response) => response));
  }

  getDiscGroupMasterList() {
    return this.baseService.GET(this.globalConstant.DISCGROUPLIST_URL).pipe(map((response) => response));
  }

  getPublisherMasterList() {
    return this.baseService.GET(this.globalConstant.PUBLISHERLIST_URL).pipe(map((response) => response));
  }

  getDisciplineMasterList(companyGroupId: number) {
    return this.baseService.GET(this.globalConstant.DISCIPLINELIST_URL + companyGroupId).pipe(map((response) => response));
  }

  getSubDisciplineMasterList(disciplineId: number) {
    return this.baseService.GET(this.globalConstant.SUBDISCIPLINELIST_URL + disciplineId).pipe(map((response) => response));
  }

  getRoyaltyDisciplineMasterList() {
    return this.baseService.GET(this.globalConstant.ROYALTYDISCIPLINELIST_URL ).pipe(map((response) => response));
  }

  getRoyaltySubDisciplineMasterList(disciplineId: number) {
    return this.baseService.GET(this.globalConstant.ROYALTYSUBDISCIPLINELIST_URL + disciplineId).pipe(map((response) => response));
  }

  getDRightsAreaMasterList() {
    return this.baseService.GET(this.globalConstant.DRIGHTSAREALIST_URL).pipe(map((response) => response));
  }

  getStateMasterList(countryId: number) {
    return this.baseService.GET(this.globalConstant.STATELIST_URL + countryId).pipe(map((response) => response));
  }

  GetWithholdingTaxTypeMasterList() {
    return this.baseService.GET(this.globalConstant.WITHHOLDINGTAXTYPELIST_URL).pipe(map((response) => response));
  }

  createClassificationCategory(classification: any) {
    //return this.baseService.POST(this.globalConstant.CREATECLASSIFICATIONCATEGORY_URL, classification).pipe(map((response) => response));
    return this.httpClient.post<any>(this.baseService.GET_URL(this.globalConstant.CREATECLASSIFICATIONCATEGORY_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  createClassificationType1(classification: any) {
    //return this.baseService.POST(this.globalConstant.CREATECLASSIFICATIONTYPEONE_URL, classification).pipe(map((response) => response));
    return this.httpClient.post<any>(this.baseService.GET_URL(this.globalConstant.CREATECLASSIFICATIONTYPEONE_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  createClassificationType2(classification: any) {
    //return this.baseService.POST(this.globalConstant.CREATECLASSIFICATIONTYPETWO_URL, classification).pipe(map((response) => response));
    return this.httpClient.post<any>(this.baseService.GET_URL(this.globalConstant.CREATECLASSIFICATIONTYPETWO_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  createClassificationType3(classification: any) {
    //return this.baseService.POST(this.globalConstant.CREATECLASSIFICATIONTYPETHREE_URL, classification).pipe(map((response) => response));
    return this.httpClient.post<any>(this.baseService.GET_URL(this.globalConstant.CREATECLASSIFICATIONTYPETHREE_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  createClassificationType4(classification: any) {
    //return this.baseService.POST(this.globalConstant.CREATECLASSIFICATIONTYPEFOUR_URL, classification).pipe(map((response) => response));
    return this.httpClient.post<any>(this.baseService.GET_URL(this.globalConstant.CREATECLASSIFICATIONTYPEFOUR_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  updateClassificationCategory(classification: any) {    
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.UPDATECLASSIFICATIONCATEGORY_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  updateClassificationType1(classification: any) {    
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.UPDATECLASSIFICATIONTYPEONE_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  updateClassificationType2(classification: any) {    
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.UPDATECLASSIFICATIONTYPETWO_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  updateClassificationType3(classification: any) {    
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.UPDATECLASSIFICATIONTYPETHREE_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  updateClassificationType4(classification: any) {    
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.UPDATECLASSIFICATIONTYPEFOUR_URL), classification,{headers: this.baseService.getHttpHeaders(), responseType: 'json'}).pipe(map((response) => response));   
  }

  getFamilyDetailList(searchText: string,page: number, pageSize: number, sortField: string, sortOrder: number, searchType: string) {
    return this.baseService.GET
    (`${this.globalConstant.FAMILYDETAILLIST_URL}?searchText=${searchText}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}&searchType=${searchType}`)
    .pipe(map((response) => response));
  }

  //*************Production Module Master List*************//

  getProductionBindingMaterialList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONBINDINGMATERIALLIST_URL).pipe(map((response) => response));
  }

  getProductionSurfaceList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONSURFACELIST_URL).pipe(map((response) => response));
  }

  getProductionColorationList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONCOLORATIONLIST_URL).pipe(map((response) => response));
  }

  getProductionSpecificationList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONSPECIFICATIONLIST_URL).pipe(map((response) => response));
  }

  getProductionFinishingList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONFINISHINGLIST_URL).pipe(map((response) => response));
  }

  getProductionBindingPaperQualityList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONBINDINGPAPERQUALITYLIST_URL).pipe(map((response) => response));
  }

  getProductionPerSheetList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPERSHEETLIST_URL).pipe(map((response) => response));
  }

  getProductionExteriorColorList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONEXTERIORCOLORLIST_URL).pipe(map((response) => response));
  }

  getProductionInteriorColorList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONINTERIORCOLORLIST_URL).pipe(map((response) => response));
  }

  getProductionBindingGrammageList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONBINDINGGRAMMAGELIST_URL).pipe(map((response) => response));
  }

  getProductionBoardThicknessList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONBOARDTHICKNESSLIST_URL).pipe(map((response) => response));
  }

  getProductionFrontPaperColorlist() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONFRONTPAPERCOLORLIST_URL).pipe(map((response) => response));
  }

  getProductionBackPaperColorlist() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONBACKPAPERCOLORLIST_URL).pipe(map((response) => response));
  }

  getProductionEndPaperGrammagelist() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONENDPAPERGRAMMAGELIST_URL).pipe(map((response) => response));
  }

  GetProductionPriceElementMasterList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPRICEELEMENTLIST_URL).pipe(map((response) => response));
  }

  getProductionClassificationMasterList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONCLASSIFICATIONLIST_URL).pipe(map((response) => response));
  }

  getProductionFormatMasterList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONFORMATLIST_URL).pipe(map((response) => response));
  }

  getProductionMethodMasterList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONMETHODLIST_URL).pipe(map((response) => response));
  }

  getProductionBindingMethodMasterList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONBINDINGMETHODLIST_URL).pipe(map((response) => response));
  }

  getProductionSpineStyleMasterList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONSPINESTYLELIST_URL).pipe(map((response) => response));
  }

  GetSupplierMasterList() {
    return this.baseService.GET(this.globalConstant.SUPPLIERLIST_URL).pipe(map((response) => response));
  }

  getProductionIllustrationTypeMasterList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONILLUSTRATIONTYPELIST_URL).pipe(map((response) => response));
  }

  getProductionIllustrationCategoryMasterList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONILLUSTRATIONCATEGORYLIST_URL).pipe(map((response) => response));
  }

  getProductionPartsNameList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPARTSNAMELIST_URL).pipe(map((response) => response));
  }

  getProductionPartsMaterialList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPARTSMATERIALLIST_URL).pipe(map((response) => response));
  }

  getProductionPartsPaperQualityList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPARTSPAPERQUALITYLIST_URL).pipe(map((response) => response));
  }

  getProductionPartsGrammageList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPARTSGRAMMAGELIST_URL).pipe(map((response) => response));
  }

  getProductionPartsPagesInchList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPARTSPAGESINCHLIST_URL).pipe(map((response) => response));
  }

  getProductionPartsColorList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPARTSCOLORLIST_URL).pipe(map((response) => response));
  }

  getProductionPaperSizeList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPAPERSIZELIST_URL).pipe(map((response) => response));
  }

  getProductionBleedList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONBLEEDLIST_URL).pipe(map((response) => response));
  }

  getProductionScheduleTemplateList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONSCHEDULETEMPLATELIST_URL).pipe(map((response) => response));
  }

  getProductionScheduleTaskList(templateId: number) {
    return this.baseService.GET(this.globalConstant.PRODUCTIONSCHEDULETASKLIST_URL + templateId).pipe(map((response) => response));
  }

  getProductionNotesTypeList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONNOTESTYPELIST_URL).pipe(map((response) => response));
  }

  getProductionPOTemplateList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPOTEMPLATELIST_URL).pipe(map((response) => response));
  }

  getProductionPODeliveryTypeList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONPODELIVERYTYPELIST_URL).pipe(map((response) => response));
  }

  getProductionEndpaperMaterialList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONENDPAPERMATERIALLIST_URL).pipe(map((response) => response));
  }

  getProductionInsertMaterialList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONINSERTMATERIALLIST_URL).pipe(map((response) => response));
  }

  getProductionInsertColorationList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONINSERTCOLORATIONLIST_URL).pipe(map((response) => response));
  }

  getProductionInsertSpecList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONINSERTSPECLIST_URL).pipe(map((response) => response));
  }

  getProductionInsertRectoColorList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONINSERTRECTOCOLORLIST_URL).pipe(map((response) => response));
  }

  getProductionInsertFinishingList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONINSERTFINISHINGLIST_URL).pipe(map((response) => response));
  }

  getProductionInsertPagesPerSheetList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONINSERTPAGESPERSHEETLIST_URL).pipe(map((response) => response));
  }

  getProductionInsertVersoColorList() {
    return this.baseService.GET(this.globalConstant.PRODUCTIONINSERTVERSOCOLORLIST_URL).pipe(map((response) => response));
  }

  //*************Production Module Master List*************//
  
  getColumnHeaderResponse(view: string, userId: number) {
    return forkJoin([
      this.getColumnHeaders(view),
      this.getColumnHeadersForUser(userId)
    ]);
  }

  getColumnHeaders(view: string) {
    return this.baseService.GET(this.globalConstant.TABLECOLUMNHEADERSLIST_URL.replace('#view', view)).pipe(map((response) => response));
  }

  getColumnHeadersForUser(userId: number) {
    return this.baseService.GET(this.globalConstant.TABLECOLUMNHEADERSLISTFORUSER_URL + userId).pipe(map((response) => response));
  }
  
  updateColumnHeadersForUser(userPreferences: any, userId: number) {
    return this.baseService.POST(this.globalConstant.UPDATETABLECOLUMNHEADERSLISTFORUSER_URL + userId, userPreferences).pipe(map((response) => response));
  }

  //---------------get TypeAhead search
   getTypeAheadSearchAffiliation(searchText:string): Observable<any[]> {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.AFFILIATIONTYPEAHEADSEARCH_URL
      .replace('#searchText', searchText)),
      this.baseService.getHeaders()).pipe(
        map(response =>
          {
            return response;

          })
      );
   }


}
